import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState } from 'react';

import clsxm from '../../../lib/clsxm';
import { REVIEW_CARD_CLASS } from '../../../lib/reviews/constants';
import { EventPageData } from '../../../types/eventPageTypes';
import { SearchResultData } from '../../../types/searchPageTypes';
import { FeaturedEvent } from '../../../types/sportPageTypes';
import RatingStarDisplay from '../../atoms/RatingStarDisplay';
import { FullRatingStar } from '../../icons/ratingStar';

interface ReviewHintProps {
  event: FeaturedEvent | EventPageData | SearchResultData;
  showText?: boolean;
  className?: string;
  showFiveStars?: boolean;
  showTotal?: boolean;
  isOnlyHint?: boolean;
}

const ReviewsHint = ({
  event,
  showFiveStars = false,
  showText = false,
  showTotal = true,
  className = '',
  isOnlyHint = true
}: ReviewHintProps) => {
  const { t } = useTranslation();

  const [showDetailedReviews, setShowDetailedReviews] = useState(false);

  const onClickShowReviewOverview = useCallback(() => {
    !isOnlyHint && (document.getElementsByClassName(REVIEW_CARD_CLASS)[0] as HTMLDivElement).click();
    !isOnlyHint && setShowDetailedReviews(true);
  }, []);

  useEffect(() => {}, [showDetailedReviews]);

  const text = [
    event.reviewsOverview.average,
    [
      (showTotal || showText) && '(',
      showTotal && event.reviewsOverview.total,
      showText && ' ' + t('event-page:reviews', { count: event.reviewsOverview.total }),
      (showTotal || showText) && ')'
    ]
      .filter(x => Boolean(x))
      .join('')
  ].join(' ');

  return (
    <div>
      <div
        className={clsxm(
          'mt-2 flex items-center space-x-1 ',
          !isOnlyHint && 'underline underline-offset-2 hover:cursor-pointer ',
          className
        )}
        onClick={onClickShowReviewOverview}>
        {showFiveStars ? (
          <RatingStarDisplay rating={event.reviewsOverview.average} starClassName="h-[18px] w-[18px] " />
        ) : (
          <FullRatingStar className="h-[18px] w-[18px]" />
        )}
        <span className="text-xs text-gray-70 ">{text}</span>
      </div>
    </div>
  );
};

export default ReviewsHint;
