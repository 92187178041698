import { FC, PropsWithChildren, useEffect } from 'react';

import clsxm from '../../lib/clsxm';
import { XIcon } from '../icons/icons';

interface ModalProps {
  widthClass?: string;
  onClose?: () => void;
  mobileFullscreen?: boolean;
  className?: string;
  showCloseButton?: boolean;
  closesOnOutsideClick?: boolean;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  onClose,
  widthClass,
  mobileFullscreen = false,
  showCloseButton = true,
  closesOnOutsideClick = true,
  className
}): JSX.Element => {
  useEffect(() => {
    const close = e => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const click = e => {
    e.stopPropagation();
    onClose();
  };

  const onClickOutsideModal = e => {
    if (closesOnOutsideClick) click(e);
  };
  return (
    <div aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed z-50 inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 h-screen" onMouseDown={onClickOutsideModal}>
        <div
          className={clsxm(
            'flex min-h-full items-center p-4 justify-center text-center sm:items-center sm:p-0 max-h-screen',
            mobileFullscreen && 'p-0 md:p-4'
          )}>
          <div
            className={clsxm(
              'py-10 overflow-y-scroll relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-8 ',
              mobileFullscreen
                ? 'w-screen h-[100vh] rounded-none overflow-hidden md:rounded-lg md:w-full md:h-full'
                : 'max-h-[94vh]',
              widthClass
            )}
            onMouseDown={e => e.stopPropagation()}>
            <div className="bg-white relative w-full">
              {showCloseButton && (
                <span
                  onClick={click}
                  className="h-8 w-full text-right z-10 flex justify-end items-center hover:cursor-pointer">
                  <XIcon className="w-4 h-4" />
                </span>
              )}
              <div className={clsxm('ah-modal-content', className)}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
