import {
  EighthTenthRatingStar,
  FourTenthRatingStar,
  HalfRatingStar,
  NinethTenthRatingStar,
  OneTenthRatingStar,
  SeventhTenthRatingStar,
  SixthTenthRatingStar,
  ThreeTenthRatingStar,
  TwoTenthRatingStar
} from '../components/icons/ratingStar';

const getTenthRatingStar = (tenth: number) => {
  switch (tenth) {
    case 1:
      return OneTenthRatingStar;
    case 2:
      return TwoTenthRatingStar;
    case 3:
      return ThreeTenthRatingStar;
    case 4:
      return FourTenthRatingStar;
    case 5:
      return HalfRatingStar;
    case 6:
      return SixthTenthRatingStar;
    case 7:
      return SeventhTenthRatingStar;
    case 8:
      return EighthTenthRatingStar;
    case 9:
      return NinethTenthRatingStar;
    case 0:
      return null;
  }
};

export default getTenthRatingStar;
