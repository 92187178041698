import getTenthRatingStar from '../../utils/getRatingStarTenth';
import { EmptyRatingStar, FullRatingStar } from '../icons/ratingStar';

interface RatingStarDisplayProps {
  rating?: number;
  starClassName: string;
  isEmpty?: boolean;
  onClick?: (x) => any;
}

const RatingStarDisplay = ({ rating, starClassName }: RatingStarDisplayProps) => {
  const fullStars = Math.floor(rating);

  const tenthFilledStar = +(rating % 1).toFixed(1) * 10;
  const TenthFilledStarComp = getTenthRatingStar(tenthFilledStar);
  const emptyStars = 5 - fullStars - Math.ceil(tenthFilledStar / 10);

  return (
    <div className="flex">
      {Array(fullStars)
        .fill(1)
        .map((_, idx) => (
          <FullRatingStar key={'fullStar' + idx} className={starClassName} />
        ))}
      {TenthFilledStarComp && <TenthFilledStarComp className={starClassName} />}
      {emptyStars > 0 &&
        Array(emptyStars)
          .fill(1)
          .map((_, idx) => <EmptyRatingStar key={'emptyStar' + idx} className={starClassName} />)}
    </div>
  );
};

export default RatingStarDisplay;
