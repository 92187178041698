import { Icon } from '../../types/Icon';

export const EmptyRatingStar: Icon = ({ className, onClick }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21" className={className} onClick={onClick}>
    <path
      d="M20.868 7.52H14.25a.636.636 0 0 1-.602-.436L11.602.789c-.19-.583-1.013-.583-1.205 0L8.351 7.082a.632.632 0 0 1-.602.436H1.135c-.613 0-.867.786-.372 1.145l5.353 3.89a.635.635 0 0 1 .23.708L4.3 19.554c-.19.583.478 1.067.975.707l5.352-3.89a.633.633 0 0 1 .745 0l5.352 3.89c.186.136.396.15.575.088a.63.63 0 0 0 .4-.796l-.4-1.228-1.646-5.064a.635.635 0 0 1 .23-.708l1.416-1.029 3.937-2.861c.497-.36.241-1.145-.372-1.145l.004.003Z"
      fill="#EBEEEF"
    />
  </svg>
);

export const FullRatingStar: Icon = ({ className, onClick }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25" className={className} onClick={onClick}>
    <path
      d="M21.869 9.52H15.25a.636.636 0 0 1-.603-.436l-2.046-6.295c-.189-.583-1.013-.583-1.204 0L9.352 9.082a.632.632 0 0 1-.603.436H2.136c-.613 0-.867.786-.373 1.145l5.353 3.89a.635.635 0 0 1 .23.708L5.3 21.554c-.189.583.478 1.067.975.707l5.353-3.89a.633.633 0 0 1 .744 0l5.353 3.89c.185.136.396.15.574.088a.63.63 0 0 0 .4-.796l-.4-1.228-1.645-5.064a.635.635 0 0 1 .23-.708l1.415-1.029 3.937-2.861c.497-.36.241-1.145-.372-1.145l.005.003Z"
      fill="#FFC700"
    />
  </svg>
);

export const OneTenthRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25">
    <path d="M3.6 9.522H2.133c-.613 0-.867.785-.372 1.144l1.837 1.337V9.522Z" fill="#FFC700" />
    <path
      d="M21.87 9.522h-6.62a.636.636 0 0 1-.602-.437l-2.046-6.296c-.19-.583-1.013-.583-1.205 0L9.351 9.083a.632.632 0 0 1-.603.437H3.6V12l3.516 2.555a.635.635 0 0 1 .23.707L5.3 21.556c-.19.583.478 1.067.975.708l5.353-3.89a.633.633 0 0 1 .745 0l5.353 3.89c.497.36 1.164-.125.975-.708l-2.046-6.294a.635.635 0 0 1 .23-.707l5.354-3.89c.497-.36.24-1.145-.373-1.145l.005.002Z"
      fill="#EBEEEF"
    />
  </svg>
);

export const TwoTenthRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25">
    <path d="m1.762 10.664 3.937 2.861V9.52H2.134c-.613 0-.867.785-.372 1.144Z" fill="#FFC700" />
    <path
      d="M21.866 9.52H15.25a.636.636 0 0 1-.603-.436L12.6 2.789c-.19-.583-1.013-.583-1.205 0L9.35 9.082a.632.632 0 0 1-.602.436h-3.05v4.006l1.415 1.029a.635.635 0 0 1 .23.708l-1.646 5.064v2.024a.62.62 0 0 0 .575-.088l5.352-3.89a.633.633 0 0 1 .745 0l5.353 3.89c.497.36 1.163-.124.974-.707L16.65 15.26a.635.635 0 0 1 .23-.708l5.353-3.89c.497-.36.241-1.145-.372-1.145l.006.003Z"
      fill="#EBEEEF"
    />
    <path d="m5.696 20.326-.4 1.229a.63.63 0 0 0 .4.796v-2.025Z" fill="#FFC700" />
  </svg>
);
export const ThreeTenthRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25">
    <path
      d="M7.801 9.52H2.137c-.614 0-.867.785-.373 1.144l5.353 3.89a.635.635 0 0 1 .23.708l-2.046 6.293c-.189.583.478 1.067.975.707L7.8 21.154V9.52Z"
      fill="#FFC700"
    />
    <path
      d="M21.869 9.52h-6.618a.636.636 0 0 1-.602-.436l-2.046-6.295c-.19-.583-1.014-.583-1.205 0L9.352 9.082a.632.632 0 0 1-.602.436h-.951v11.635l3.827-2.781a.633.633 0 0 1 .745 0l5.352 3.89c.497.359 1.164-.125.975-.709l-2.046-6.292a.635.635 0 0 1 .23-.708l5.353-3.89c.497-.36.24-1.145-.372-1.145l.006.003Z"
      fill="#EBEEEF"
    />
  </svg>
);
export const FourTenthRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25">
    <path
      d="m9.902 7.398-.547 1.683a.632.632 0 0 1-.602.437H2.135c-.613 0-.867.785-.372 1.144l5.353 3.89a.635.635 0 0 1 .23.708L5.3 21.553c-.19.583.478 1.067.975.707L9.9 19.627V7.398h.002Z"
      fill="#FFC700"
    />
    <path
      d="M21.868 9.52h-6.617a.636.636 0 0 1-.603-.436l-2.046-6.295c-.19-.583-1.013-.583-1.205 0L9.9 7.399v12.229l1.727-1.256a.633.633 0 0 1 .745 0l5.353 3.89c.497.359 1.163-.125.974-.709l-2.046-6.292a.635.635 0 0 1 .23-.708l5.353-3.89c.497-.361.241-1.145-.372-1.145l.004.003Z"
      fill="#EBEEEF"
    />
  </svg>
);

export const HalfRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25" className={className}>
    <path
      d="M11.398 2.788 9.354 9.081a.632.632 0 0 1-.602.437H2.134c-.613 0-.867.785-.372 1.144l5.353 3.89a.635.635 0 0 1 .23.708l-2.046 6.293c-.19.583.478 1.067.975.708l5.352-3.89a.64.64 0 0 1 .373-.12V2.35a.622.622 0 0 0-.603.437h.002Z"
      fill="#FFC700"
    />
    <path
      d="m12.373 18.373 5.352 3.89c.497.36 1.164-.125.975-.708l-2.046-6.293a.635.635 0 0 1 .23-.708l5.353-3.89c.497-.359.24-1.144-.372-1.144h-6.618a.636.636 0 0 1-.602-.437L12.6 2.788a.62.62 0 0 0-.6-.436v15.9c.13 0 .26.042.372.121Z"
      fill="#EBEEEF"
    />
  </svg>
);

export const SixthTenthRatingStar = ({ className }) => (
  <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M14.647 8.73c.083.26.327.436.602.436h6.618c.613 0 .867.785.372 1.145l-5.353 3.89a.635.635 0 0 0-.23.707l2.046 6.293c.19.583-.478 1.067-.975.708L13 18.475 13 3.684l1.647 5.045Z"
      fill="#EBEEEF"
    />
    <path
      d="M2.134 9.17h6.618a.636.636 0 0 0 .602-.438l2.044-6.295c.19-.583 1.014-.583 1.205 0l.393 1.235L13 18.477l-.195-.143-.432-.314a.633.633 0 0 0-.745 0l-5.352 3.89c-.497.36-1.164-.125-.975-.708l2.046-6.293a.635.635 0 0 0-.23-.708l-5.355-3.887c-.497-.362-.241-1.145.372-1.145Z"
      fill="#FFC700"
    />
  </svg>
);

export const SeventhTenthRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25">
    <path
      d="m14.1 7.398.547 1.683c.083.26.327.437.602.437h6.618c.613 0 .867.785.372 1.144l-5.353 3.89a.635.635 0 0 0-.23.708l2.046 6.293c.19.583-.478 1.067-.975.707l-3.625-2.633V7.398H14.1Z"
      fill="#EBEEEF"
    />
    <path
      d="M2.134 9.52h6.618a.636.636 0 0 0 .602-.436l2.044-6.295c.19-.583 1.014-.583 1.205 0l1.497 4.61v12.229l-1.727-1.256a.633.633 0 0 0-.745 0l-5.352 3.89c-.497.359-1.164-.125-.975-.709l2.046-6.292a.635.635 0 0 0-.23-.708l-5.355-3.888c-.497-.361-.241-1.144.372-1.144Z"
      fill="#FFC700"
    />
  </svg>
);
export const EighthTenthRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25">
    <path
      d="M16.2 9.52h5.667c.613 0 .867.785.372 1.144l-5.352 3.89a.635.635 0 0 0-.23.708l2.045 6.293c.19.583-.477 1.067-.974.707l-1.526-1.108V9.52H16.2Z"
      fill="#EBEEEF"
    />
    <path
      d="M2.135 9.52h6.617a.636.636 0 0 0 .603-.436l2.044-6.295c.189-.583 1.013-.583 1.205 0l2.046 6.293c.083.26.326.436.602.436h.95v11.635l-3.827-2.781a.633.633 0 0 0-.744 0l-5.353 3.89c-.497.359-1.164-.125-.974-.709l2.046-6.292a.635.635 0 0 0-.23-.708l-5.358-3.888c-.496-.36-.24-1.144.373-1.144Z"
      fill="#FFC700"
    />
  </svg>
);
export const NinethTenthRatingStar = ({ className }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25">
    <path
      d="m18.3 20.326.4 1.229a.63.63 0 0 1-.4.796v-2.025ZM22.238 10.664l-3.937 2.861V9.52h3.567c.613 0 .867.785.372 1.144h-.002Z"
      fill="#EBEEEF"
    />
    <path
      d="M2.133 9.52h6.618a.636.636 0 0 0 .602-.436l2.044-6.295c.19-.583 1.013-.583 1.205 0l2.046 6.293c.084.26.327.436.602.436h3.05v4.006l-1.415 1.029a.635.635 0 0 0-.23.708l1.646 5.064v2.024a.62.62 0 0 1-.574-.088l-5.353-3.89a.633.633 0 0 0-.745 0l-5.352 3.89c-.497.36-1.164-.124-.975-.707l2.046-6.293a.635.635 0 0 0-.23-.708L1.76 10.665c-.497-.36-.241-1.144.372-1.144Z"
      fill="#FFC700"
    />
  </svg>
);

export const EmptyOutlinedRatingStar: Icon = ({ className, onClick }) => (
  <svg fill="none" viewBox="0 0 24 25" className={className} onClick={onClick}>
    <path d="m21.8685 9.66907.0005-.0021c.0389.00087.0612.01231.076.02379.0185.0143.0361.03768.0461.06847.0101.03078.0095.05966.0031.08148-.0053.01808-.017.04113-.0507.06556l-.0011.00074-3.937 2.86129-.0001.0001-1.4155 1.0283-.0005.0003c-.3967.289-.5617.8002-.4117 1.2654l.0003.0012 1.6459 5.0644.0001.0004.3998 1.2275c.0001.0001.0001.0003.0002.0004.0153.0477.0073.0774-.0051.1-.0162.0294-.0471.0566-.0847.0697-.0197.0069-.0383.0085-.0544.0064-.0147-.002-.0345-.0078-.0594-.026l-.0015-.0011-5.3523-3.8895c-.0001 0-.0002-.0001-.0002-.0001-.397-.2889-.9358-.2889-1.3327 0-.0001 0-.0001.0001-.0002.0001l-5.35132 3.8888c-.00008 0-.00016.0001-.00024.0001-.03415.0247-.06043.0289-.08008.0283-.02349-.0007-.05147-.0094-.07763-.0284-.02617-.019-.04278-.0427-.05054-.0644-.00646-.018-.01059-.0439.00239-.0838l2.04594-6.2928.00039-.0012c.14995-.4652-.01499-.9764-.41177-1.2654l-.00039-.0002-5.35273-3.88981-.00016-.00012c-.03337-.02423-.04519-.04729-.05059-.0658-.00648-.02222-.007-.05142.00302-.08232.01002-.03089.02754-.05412.04576-.06823.01514-.01173.0384-.02361.08024-.02361h6.61346c.49043 0 .92676-.31445 1.07802-.78252l.00027-.00083 2.04516-6.29028c0-.00014.0001-.00029.0001-.00044.0133-.04007.0322-.05901.0485-.07011.0195-.01327.0472-.02273.0795-.02273.0324 0 .0597.00945.0787.0224.0158.01079.0344.02933.0474.0693v.00013l2.046 6.29505.0008.00238c.1533.46379.5869.77981 1.0771.77981h6.6178Z" />
  </svg>
);
